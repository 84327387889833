/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import HeroDefault from 'components/elements/HeroDefault'
import TitleDefault from 'components/elements/TitleDefault'
import BlogPosts from 'components/BlogPosts'
import Testimonials from 'components/Testimonials'
import ContentDefault from 'components/elements/ContentDefault'

const Container = styled.div`
  position: relative;  
`

const Blogs = styled.div`
  position: relative;
  background-color: ${props => props.theme.color.secondary};
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      acf {
        bannerImage: banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              meta: fluid(maxWidth: 800, toFormat: JPG) {
                src
              }
            }
          }
        }

        testimonial {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          quote
        }
      }
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }

    posts: allWordpressPost(sort: {fields: date, order: DESC}) {
      edges {
        node {
          wpId: wordpress_id
        }
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, title, yoastMeta, acf },
    posts: { edges: postList }
  },
}) => {
  return (
    <Layout>
      <SEO
        yoast={{ meta: yoastMeta }}
        pathname={path}
        image={acf.bannerImage.localFile.childImageSharp.fluid.src}
        article
      />
      <HeroDefault 
        image={acf.bannerImage} 
      />
      <section>
        <Container className="container py-5">
          <div className="row">
            <div className="col">
              <TitleDefault h1 className="ml-lg-5 pl-lg-5"><ContentDefault content={title} /></TitleDefault>
            </div>
          </div>
        </Container>
      </section>
      <Blogs>
        <Container className="container py-5">
          <div className="row pt-4 px-3 px-lg-0">
            <div className="col-lg-2 d-none d-lg-block" />
            <div className="col-lg-8">
              <BlogPosts postIds={postList.map(({ node: { wpId } }) => wpId )} />
            </div>
            <div className="col-lg-2 d-none d-lg-block" />
          </div>
        </Container>
      </Blogs>
      <section>
        <Testimonials id={[acf.testimonial.quote]} image={acf.testimonial.image.localFile.childImageSharp.fluid} />
      </section>
    </Layout>
  )
}

export default PageTemplate
